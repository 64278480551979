//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEdit from '@/components/GeneralEdit';
export default {
  name: 'Index',
  components: {
    GeneralEdit: GeneralEdit
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      url: {
        getUrl: '/position'
      },
      rules: {
        b_position_name: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 100,
          message: this.$t("rules.common.maxlength100"),
          trigger: 'blur'
        }],
        b_org_id: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'change'
        }]
      }
    };
  }
};